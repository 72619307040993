<template>
  <div>
    <div style="background-color: #fcfcfc;padding: 20px">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>文章详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-row>
          <el-col :span="24" style="text-align: left;margin: 20px 0;font-weight: bold;"><div>{{articlelist.Tittle}}</div></el-col>
        </el-row>
        <el-row>
          <el-col :span="24" style="text-align: left;margin: 10px 0;font-size: 14px;">
            <div class="message">作者：admin </div>
            <div class="message" v-if="articlelist.Createdate">发布时间：{{articlelist.Createdate.replace('T',' ')}}</div>
            <div class="message">浏览：{{articlelist.Browsecount}}</div>
            <div class="message">文章标签：<a :href="'/tag?val='+tag.Id" style="color: #1989fa;margin-left: 10px" v-for="tag in (reverseMessage(articlelist.Id)) ">{{tag.Tagname}}</a> </div>
            <div class="message">文章分类：<a :href="'/classify?val='+classify.Id" style="color: #1989fa;margin-left: 10px" v-for="classify in (reverseMessageClassify(articlelist.Id)) ">{{classify.Classifyname}}</a></div>
            <div class="message">评论：{{replycount}}</div>
          </el-col>
        </el-row>
      </div>
      <el-divider></el-divider>
      <div v-html="articlelist.Content" class="contentarticle">
      </div>
    </div>
    <div style="background-color: #fcfcfc;padding: 20px;margin-top: 10px">
      <el-row>
        <el-col :span="24" style="text-align: left;font-weight: bold;;margin-bottom: 5px"><div>评论</div></el-col>
      </el-row>
      <div v-for="(item,index) in replylist" style="text-align: left;font-size: 20px">
        <div>{{index+1}}L</div>
        <div class="el-icon-s-custom" style="margin: 20px;" v-if="item.Createdate"> {{item.Nickname}} 评论时间 {{item.Createdate.replace('T',' ')}}</div>
        <div style="margin: 20px 30px;font-size: 16px">{{item.Content}}</div>
        <div style="margin: 20px;" v-if="item.Reply"> 作者 回复  {{item.Replydate.replace('T',' ')}}</div>
        <div style="margin: 20px 30px;font-size: 16px" v-if="item.Reply">{{item.Reply}}</div>
        <el-divider></el-divider>
      </div>
    </div>
    <div style="background-color: #fcfcfc;padding: 20px;margin-top: 10px">
      <el-row>
        <el-col :span="24" style="text-align: left;font-weight: bold;"><div>发表评论</div></el-col>
      </el-row>
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="addForm.nickname" placeholder="请输入昵称"></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="addForm.email" placeholder="请输入正确的邮箱，作者将会回复，邮箱接收消息"></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <el-input v-model="addForm.content" type="textarea" placeholder="请输入内容" rows="3"></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="onsubmitreply">提交</el-button>
      <el-button type="primary" @click="onreply">重置</el-button>
    </div>

  </div>
</template>

<script>
import {
  AddBlogReply,
  GetBlogClasifyAllList,
  GetBlogContent, GetBlogReplyDetal,
  GetBlogReplyList,
  GetBlogTagsAllList, UpdateBrowseCount
} from "@/network/blog";

import hljs from 'highlight.js'
import 'highlight.js/styles/tomorrow-night-bright.css'

export default {
  name: "Article",
  data(){
    //自定义校验email字段
    // let checkemail = (rule, value, callback) => {
    //   if (!value)
    //   {
    //     return callback(new Error('邮箱不能为空'));
    //   }
    //   const regnum =/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
    //   if (!regnum.test(value)) {
    //     callback(new Error('请输入正确的邮箱格式'));
    //   } else {
    //     callback();
    //   }
    // };
    return{
      articlelist:[],
      taglist:[],
      classifylist:[],
      replycount:0,
      addForm:{
        nickname:'',
        email:'',
        content:'',
      },
      //新增用户字段验证规则
      addFormRules:{
        nickname: [
          { required: true, message: '请输入昵称', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在 3 到 10 个字', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入内容', trigger: 'blur' },
          { min: 3, max: 512, message: '长度在 3 到 512 个字', trigger: 'blur' }
        ],
        // email: [
        //   { validator: checkemail, trigger: 'blur' }
        // ]
      },
      ip: '',
      replylist:[],
    }
  },
  created() {
    //加载文章
    this.initcontent()
    //加载标签
    this.inittag()
    //加载分类
    this.initclassify()
    //加载评论数量
    this.initreply()
    //加载评论
    this.initreplylist()
    //浏览记录+1
    this.initbrowsecount()
  },
  methods:{
    //加载文章
    initcontent(){
      //请求接口数据
      GetBlogContent(this.$route.query.val).then(res => {
        this.articlelist =res.data
        //等页面渲染完成后加载对html系节点处理
        this.$nextTick(()=>{
          this.ueditor()
        });
      }).catch(err => {
        console.log(err);
      })
    },
    //加载评论数量
    initreply(){
      GetBlogReplyList(this.$route.query.val).then(res => {
        this.replycount =res.data
      }).catch(err => {
        console.log(err);
      })
    },
    //加载评论
    initreplylist(){
      GetBlogReplyDetal(this.$route.query.val).then(res => {
        this.replylist =res.data
      }).catch(err => {
        console.log(err);
      })
    },
    // 获取文章tags
    reverseMessage(id) {
      let tag = []
      for (let item of this.taglist){
        if (item.Blogid===id){
          tag.push(item)
        }
      }
      return tag
    },
    // 获取文章分类
    reverseMessageClassify(id) {
      let tag = []
      for (let item of this.classifylist){
        if (item.Blogid===id){
          tag.push(item)
        }
      }
      return tag
    },
    //初始化tag
    inittag(){
      GetBlogTagsAllList().then(res => {
        this.taglist =res.data
      }).catch(err => {
        console.log(err);
      })
    },
    //初始化分类
    initclassify(){
      GetBlogClasifyAllList().then(res => {
        this.classifylist =res.data
      }).catch(err => {
        console.log(err);
      })
    },
    onreply(){
      this.$refs.addFormRef.resetFields()
    },
    onsubmitreply(){
      this.$refs.addFormRef.validate(call=>{
        if (!call)
          return
        let fm =new FormData()
        fm.append('blogid',this.$route.query.val)
        fm.append('nickname',this.addForm.nickname)
        fm.append('useremail',this.addForm.email)
        fm.append('content',this.addForm.content)
        AddBlogReply(fm).then(res => {
          this.initreplylist()
          this.$message({
            message: '评论成功！',
            type: 'success'
          })
        }).catch(err => {
          console.log(err);
        })
      })
    },
    formdatetime(time){
      return time.replace('T',' ')
    },
    ueditor(){
      //UEditor的代码中<pre><code></>code><pre>标签包裹的代码，所以执行下面的操作为其添加标签。
      let prelist = document.getElementsByTagName("pre");
      for(let i = 0; i < prelist.length; i++) {
        let pre = document.getElementsByTagName("pre")[i];
        let code = document.getElementsByTagName("pre")[i].innerHTML;
        pre.innerHTML = '<code>'+code+'</code>';
      }
      // 实现代码高亮的函数。10.6.0版本以后使用highlightAll()进行渲染
      //Deprecated as of 10.6.0. initHighlightingOnLoad() deprecated. Use highlightAll() now.
      hljs.highlightAll();
    },
    //浏览记录，不存ip
    initbrowsecount(){
      UpdateBrowseCount(this.$route.query.val).then(res => {
        console.log(res.data)
      }).catch(err => {
        console.log(err);
      })
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.message{
  text-align: left;
  margin-right: 10px;
  font-size: 14px;
  float: left;
}
.contentarticle{
  text-align: left;
}
#mycode {
  background: #2d2d2d;
  color: #ffffff;

}
</style>
